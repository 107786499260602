body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.plus {
  display: inline-block;
  width: 100%;
  height: 100%;

  background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0), #000;
  background-position: center;
  background-size:
    50% 2px,
    2px 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}

.alt {
  background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  background-position: center;
  background-size:
    50% 2px,
    2px 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}
